  <template>
  <div class="d-flex flex-column">
    <picker
      document-types="group-contributors"
      :disabled="!!(collaborationTask.workflowId || statusWillCloseCollaboration)"
      group-contributor-types="collaboration-task"
      :label="$t('t.UsePredefinedGroup')"
      :selected-id.sync="groupContributorId"
      class="mb-6 align-self-start"
    />
    <drag-and-drop>
      <template v-slot="{setDraggable, acceptDrop}">
        <v-row class="flex-direction-column">
          <v-col>
            <v-card
              elevation=0
              class="bordered-item"
            >
              <v-card-text v-on="acceptDrop(dropOwner)">
                <user-picker
                  :selection.sync="owner"
                  :scope="accountScope"
                  document-types="users"
                  :is-chip-draggable="!!setDraggable && !collaborationTask.workflowId && collaborationTask.canUpdate"
                  :on-chip="setDraggable"
                  :readonly="!!(collaborationTask.workflowId !== null || !collaborationTask.canUpdate || statusWillCloseCollaboration)"
                  :show-picker="!collaborationTask.owner"
                  :warn-no-consultation="consultationRule"
                  :rules="required"
                  :external-user-cache-type="externalUserCacheType"
                >
                  <template v-slot:prepend>
                    <small>{{$t('t.Owner')}}</small>
                  </template>
                </user-picker>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              elevation=0
              class="bordered-item"
            >
              <v-card-text>
                <user-picker
                  :selection.sync="creator"
                  :readonly="true"
                  document-types="users"
                >
                  <template v-slot:prepend>
                    <small>{{$t('t.Creator')}}</small>
                  </template>
                </user-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="flex-direction-column">
          <v-col>
            <v-card
              elevation=0
              class="bordered-item"
            >
              <v-card-text v-on="acceptDrop(dropParticipant)">
                <user-picker
                  :selection.sync="participantList"
                  :scope="accountScope"
                  document-types="users"
                  :is-chip-draggable="!!setDraggable && !collaborationTask.workflowId  && collaborationTask.canUpdate"
                  :on-chip="setDraggable"
                  required-check-box
                  :warn-no-consultation="consultationRule"
                  :readonly="!!(!collaborationTask.canUpdate || collaborationTask.workflowId !== null || statusWillCloseCollaboration)"
                  :placeholder="`${$t('t.AddParticipant')}...`"
                  :external-user-cache-type="externalUserCacheType"
                >
                  <template v-slot:prepend>
                    <small>{{$t('t.Participants')}}</small>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>{{$t('t.NoParticipantsAvailables')}}</v-list-item-content>
                    </v-list-item>
                  </template>
                </user-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </drag-and-drop>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    Picker: () => import('@/components/picker'),
    DragAndDrop: () => import('@/components/drag-and-drop'),
    UserPicker: () => import('@/components/user-picker')
  },
  data () {
    return {
      externalUserCacheType: CacheType.UserRef,
      required: [
        v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    creator () {
      return { id: this.collaborationTask.createBy }
    },
    groupContributorId: {
      get () {
        return this.collaborationTask.groupContributorId
      },
      async set (id) {
        let owner
        let participantList
        if (id) {
          const groupContributor = await this.$http().get(`/core/v6/group-contributor/${id}/${this.collaborationTask.accountId}/${this.collaborationTask.createBy}`)
          owner = groupContributor.data.owner
          participantList = groupContributor.data.participants
        } else {
          owner = { id: this.$store.getters.currentUser.id, userRole: null, userRoleName: null, indexOrder: null }
          participantList = []
        }
        this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { groupContributorId: id, owner, participantList }))
      }
    },
    owner: {
      get: function () {
        return this.collaborationTask.owner
      },
      set: function (owner) {
        const participantList = [...this.participantList]
        if (owner) {
          const index = participantList.map(p => p.id).indexOf(owner.id)
          if (index > -1) {
            participantList.splice(index, 1)
          }
        }
        if (owner?.id !== this.collaborationTask.owner?.id) {
          this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { owner, participantList }))
        }
        this.resetContributor()
      }
    },
    participantList: {
      get: function () {
        return this.collaborationTask.participantList
      },
      set: function (participantList) {
        let owner = this.owner
        if (participantList.map(p => p.id).includes(this.owner?.id)) {
          owner = undefined
        }
        if (!this.lodash.isEqual(participantList.map(p => p.id), this.collaborationTask.participantList.map(p => p.id)) ||
          !this.lodash.isEqual(participantList.map(p => p.isRequired), this.collaborationTask.participantList.map(p => p.isRequired))) {
          this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { owner, participantList }))
        }
        this.resetContributor()
      }
    },
    accountScope () {
      return Scope.account(this.collaborationTask.accountId)
    }
  },
  methods: {
    update () {
      this.$nextTick(() => { this.groupContributorId = this.collaborationTask.groupContributorId })
    },
    dropOwner (user) {
      if (this.owner && this.owner.id !== user.id) {
        this.participantList = [...this.participantList, this.owner]
      }
      this.$nextTick(() => (this.owner = user))
    },
    dropParticipant (user) {
      if (this.participantList.map(p => p.id).indexOf(user.id) === -1) {
        this.participantList = [...this.participantList, user]
      }
    },
    resetContributor () {
      this.$nextTick(() => (this.$emit('update:collaboration-task', Object.assign({}, this.collaborationTask, { groupContributorId: null }))))
    },
    consultationRule (user) {
      return Boolean(!this.collaborationTask.isNew && (user.isRequired || user.id === this.owner?.id) && !user.consultationDate && user.id !== this.$store.getters.currentUser.id)
    }
  },
  props: {
    collaborationTask: Object,
    statusWillCloseCollaboration: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
